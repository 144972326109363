import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import tvu1 from '../../../images/taiteovi4-768x631.jpg'
import tvu2 from '../../../images/VezOwi.gif'


const topeltVoldikUksed = () => (
  <Layout>
    <SEO title="Ruumijagajad" />
    
    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
              <h1>VOLDIKUKSED</h1>
              <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
   </div>
   
   <div className="epoporandad" style={{
          maxWidth: 1300,
          margin: 'auto',
        }}>

<div className="wrapper-epo">
        <Link to='/ruumijagajad/voldikuksed/'
        activeStyle={{ borderBottom: '3px solid #ffa609' }}
        partiallyActive={true}>
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to='/ruumijagajad/lootsuksed/'>
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
          </Link>
        <Link to='/ruumijagajad/siirdeseinad/'>
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to='/ruumijagajad/voldikseinad/'> 
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
         <Link to='/ruumijagajad/referentsid/'>
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="tvu-wrapper">
        <div className="tvu-text">
            <h3>Topeltvoldikused</h3>
            <p>FP – 300 topeltliigenduksed on parim liigendukse variant juhul,
            kui soovitakse lisaks ruumi jagamisele ka eristada heli. Võimalik varustada lukuga.
            Pinnad spoonitud või värvitud. Laiemad uksed soovitav paigaldada kaheosalisena (keskelt avanev), 
            sest kokkupakitult on ukseploki paksus ca 10% ava laiusest ning ploki paksus võib olla liialt esile tungiv.
            Helipidavus – 30-36 dB / 2000 Hz</p>
        </div>
        <div className="subpage-img tvu-img">
            <img src={tvu1} alt="topeltvoldikuks"></img>
        </div>
      </div>

  </div>

  <div className="subpage-img tvu-img2">
           <img src={tvu2} alt="topeltvoldikuks-gif"></img>
      </div>

  </Layout>
)

export default topeltVoldikUksed